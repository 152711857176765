import React, { FunctionComponent } from "react"
import { Box, Heading, Text } from "@chakra-ui/layout"
import { Flex } from "@chakra-ui/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import { colors } from "../theme/theme"

const StyledText = styled(Text)`
  margin: 0.5rem 0;
`

export const Imprint: FunctionComponent = () => {
  return (
    <>
      <Layout disableCookiePopup={true}>
        <Seo
          title="Imprint | HendrikGruber.com"
          meta={[
            {
              name: "Imprint",
              content: "Imprint and contact information for HendrikGruber.com",
            },
          ]}
        />

        <Flex width="100%" flexDir="column">
          <Box width="100%">
            <Heading as="h1">Imprint</Heading>
          </Box>
          <Box width="100%" marginTop="2rem">
            <Heading margin="0.5rem 0" as="h2" fontSize="1.5rem">
              Contact Information
            </Heading>
            <StyledText>Hendrik Gruber</StyledText>
            <StyledText>Kattreinstraße 84, 64295 Darmstadt</StyledText>
            <StyledText>
              <a
                href="mailto:gruber.hendrik@gmail.com"
                target="_blank"
                style={{ fontStyle: "italic", color: colors.blue.blue2 }}
              >
                gruber.hendrik@gmail.com
              </a>
            </StyledText>
          </Box>
        </Flex>
      </Layout>
    </>
  )
}

export default Imprint
